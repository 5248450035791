import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
// import MaterialTable from 'material-table';
import { useNavigate } from 'react-router-dom';
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { mapValues, groupBy, capitalize, sortBy } from 'lodash';
import clsx from 'clsx';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  DeleteIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
} from '../thirdParty';
import theme from '../theme';
import ConfirmDialog from '../DeleteFavoriteDialog';
import { trendsFavSplitter, favoriteTypeLabel as type } from '../constants';

const undefinedValue = 'undefined';
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableBox: {
    width: '100%',
    height: isFavoritePage => (isFavoritePage ? '350px' : 'auto'),
    overflow: isFavoritePage => (isFavoritePage ? 'auto' : ''),
    display: 'inline-block',
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '5px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px grey',
      webkitBoxShadow: 'inset 0 0 5px grey',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'grey',
      borderRadius: '10px',
    },
  },
  mainPortfolioTable: {
    '& th:first-child': {
      textAlign: 'left',
    },
    '& td:first-child': {
      textAlign: 'left',
    },
    '& td': {
      fontSize: '9px',
      padding: '0 2px 0 0',
      border: 0,
      textAlign: 'left',
    },
    '& th': {
      fontSize: '11px',
      padding: 0,
      textAlign: 'left',
      fontWeight: 600,
    },
    '& .MuiTableCell-root': {
      padding: '0',
      border: 0,
    },
  },
  mainSiteTable: {
    '& th:first-child': {
      textAlign: 'left',
      width: isFavoritePage => (isFavoritePage ? '' : '365px'),
    },
    '& td:first-child': {
      textAlign: 'left',
    },
    '& td': {
      fontSize: '9px',
      padding: '0 2px 0 0',
      border: 0,
      width: isFavoritePage => (isFavoritePage ? '240px' : '215px'),
      textAlign: 'left',
    },
    '& th': {
      fontSize: '11px',
      padding: 0,
      width: isFavoritePage => (isFavoritePage ? '375px' : '190px'),
      textAlign: 'left',
      fontWeight: 600,
    },
    '& .MuiTableCell-root': {
      padding: '0',
      // border: 0,
    },
  },
  mainAssetTable: {
    '& th:first-child': {
      textAlign: 'left',
      width: isFavoritePage => (isFavoritePage ? '' : '365px'),
    },
    '& td:first-child': {
      textAlign: 'left',
    },
    '& td': {
      fontSize: '9px',
      padding: '0 2px 0 0',
      border: 0,
      width: isFavoritePage => (isFavoritePage ? '240px' : '215px'),
      textAlign: 'left',
    },
    '& th': {
      fontSize: '11px',
      padding: 0,
      width: isFavoritePage => (isFavoritePage ? '275px' : '190px'),
      textAlign: 'left',
      fontWeight: 600,
    },
    '& .MuiTableCell-root': {
      padding: '0',
      // border: 0,
    },
  },
  accTitle: {
    height: '25px',
    fontSize: '10px',
    minHeight: '35px !important',
    borderBottom: '1px solid #ccc',
    '& .Mui-expanded': {
      margin: 0,
    },
  },
  accDetails: {
    paddingLeft: '12px',
    paddingBottom: 0,
  },
  favTablePanel: {
    width: '100%',
  },
  // tableTd: { width: '325px !important' },
  urlLabel: {
    cursor: 'pointer',
    color: '#336EFF',
    paddingRight: '14px',
    wordBreak: 'break-word',
  },
  deleteLabel: {
    cursor: 'pointer',
    width: '10px',
    padding: '2px 15px 0',
  },
  portFolioTable: {
    fontSize: '9px',
    border: 0,
    '& td': {
      padding: '0',
      border: 0,
    },
  },
  pageNameLable: {
    fontSize: '10px',
    display: 'flex',
    alignItems: 'center',
    width: isFavoritePage => (isFavoritePage ? '' : 'auto'),
    marginBottom: '15px',
    height: '20px',
    paddingTop: '10px',
    fontWeight: 600,
  },
  disabledFilters: { opacity: '0.5', pointerEvents: 'none' },
  deleteIcon: { cursor: 'pointer' },
  rowBox: { marginLeft: '-20px' },
  rowPortFolioBox: { marginLeft: '10px' },
  rowSiteBox: { marginLeft: '-1px' },
  rowAssetBox: { marginLeft: '-1px' },
  folderAcc: { fontWeight: 400, fontSize: '12px' },
  mainAcc: {
    boxShadow: 'none',
  },
  firstColFav: {
    width: isFavoritePage => (isFavoritePage ? '250px' : '248px'),
  },
  firstColFavAsset: {
    width: isFavoritePage => (isFavoritePage ? '255px' : '185px'),
  },
  firstColPortfolo: {
    width: 'auto',
    marginTop: '-18px',
  },
});

const tableTheme = size =>
  createTheme({
    palette: {
      primary: {
        main: theme.palette.primary.main,
      },
      secondary: {
        main: theme?.color?.blue,
      },
    },
    overrides: {
      MuiTableCell: {
        root: {
          fontSize: size === 'x-small' ? '10px' : '0.75rem',
          fontWeight: 600,
        },
        head: {
          fontSize: size === 'x-small' ? '10px' : '0.75rem',
          fontWeight: 600,
        },
      },
      MuiTableRow: {
        root: {
          fontSize: size === 'x-small' ? '10px' : '0.75rem',
          fontWeight: 500,
        },
      },
      MuiInputBase: {
        input: {
          fontSize: size === 'x-small' ? '10px' : '0.75rem',
          fontWeight: 500,
        },
      },
      MuiPaper: {
        elevation2: {
          boxShadow: 'none',
        },
      },
      MuiTypography: {
        h6: { color: '#282828', fontSize: '16px', fontWeight: '400' },
      },
      MuiIcon: {
        root: {
          fontSize: size === 'x-small' ? 16 : 22,
        },
      },
    },
  });
function FavoriteTableTree(props) {
  const [open, setOpen] = useState(false);
  const [foldOpen, setfoldOpen] = useState(false);
  const [favoriteDataRow, setFavoriteDataRow] = useState([]);
  const [favoriteTableData, setFavoriteTableData] = useState([]);
  const [folderState, setFolderState] = useState({});
  const [rowToDelete, setRowToDelete] = useState([]);
  const [fvexpanded, setExpanded] = React.useState('');
  const { t } = useTranslation();
  const history = useNavigate();
  const classes = useRowStyles(props?.isFavoritePage);
  const favoriteFolderList = props?.data?.filter(o => !o.name);
  const favoriteData = props?.data?.filter(o => o.name);

  useMemo(() => {
    const favoriteArr = [];
    favoriteFolderList?.forEach(fvfold => {
      const favoriteObj = {
        folderName: fvfold.id,
        assetSiteLists: {},
      };
      favoriteObj.assetSiteLists = [];
      favoriteData?.forEach(fvData => {
        if (fvfold?.id === fvData?.parentId) {
          favoriteObj.assetSiteLists?.push(fvData);
        }
      });
      favoriteArr?.push(favoriteObj);
    });
    setFavoriteDataRow(favoriteArr);
  }, [props?.data]);

  useMemo(() => {
    const favoriteTableRows = [];
    favoriteDataRow?.forEach(folObj => {
      const favoriteObj = {
        folderName: folObj.folderName,
        assetSiteLists: { assets: {}, sites: {}, trends: {} },
      };
      favoriteObj.assetSiteLists.assets = [];
      favoriteObj.assetSiteLists.sites = [];
      favoriteObj.assetSiteLists.trends = [];
      folObj?.assetSiteLists?.forEach(assetSiteObj => {
        if (assetSiteObj?.type === type.ASSET)
          favoriteObj.assetSiteLists?.assets.push(assetSiteObj);
        else if (assetSiteObj?.type === type.SITE)
          favoriteObj.assetSiteLists?.sites.push(assetSiteObj);
        else if (assetSiteObj?.type === type.TREND)
          favoriteObj.assetSiteLists?.trends.push(assetSiteObj);
      });
      favoriteTableRows.push(favoriteObj);
    });
    setFavoriteTableData(favoriteTableRows);
  }, [favoriteDataRow]);

  const favTableSitesObj = sites => groupBy(sites, 'favorite_page_id');

  useMemo(() => {
    const favoriteParentChildSelection = () => {
      let favObj = {};
      favoriteDataRow?.forEach(ele => {
        if (ele?.folderName) {
          favObj[ele?.folderName] = {};
          const favFolder = favObj[ele?.folderName];
          favFolder.status = false;
          let assets = {};
          let favPageId = {};
          ele?.assetSiteLists?.forEach(childEle => {
            let siteAssetId = '';
            if (childEle?.type === type.SITE)
              siteAssetId = `${childEle?.site_uuid}-${childEle?.favorite_page_id}`;
            else if (childEle?.type === type.ASSET)
              siteAssetId = `${childEle?.asset_uuid}-${childEle?.favorite_page_id}`;
            else if (childEle?.type === type.TREND)
              siteAssetId = `${childEle?.trend_link}`;

            assets = { ...assets, [siteAssetId]: false };
            favPageId = { ...favPageId, [childEle?.favorite_page_id]: false };
            favObj = {
              ...favObj,
              [childEle?.parentId]: {
                ...favFolder,
                assets,
                favPageId,
              },
            };
          });
        }
      });
      setFolderState(favObj);
    };
    favoriteParentChildSelection();
  }, [favoriteDataRow]);

  const handleFolderChange = panelfolder => (event, newExpandedFolder) => {
    setExpanded(newExpandedFolder ? panelfolder : false);
  };

  const FavTableRows = ({ assetSiteLists, folderObj, favoriteTransObj }) => {
    const [favSiteExpanded, setSiteFavExpanded] = React.useState('');
    const [favAssetExpanded, setAssetFavExpanded] = React.useState('');
    const [favTrendExpanded, setfavTrendExpanded] = React.useState('');
    const [fvSiteAssetOpen, setFvSiteAssetOpen] = useState({});
    // const [fvPortFolioOpen, setFvPortFolioOpen] = useState(false);

    useMemo(() => {
      const favoriteLists = {};
      assetSiteLists?.assets?.forEach(assetObj => {
        favoriteLists[assetObj.favorite_page_id] =
          folderState?.[assetObj.parentId]?.favPageId?.[
            assetObj.favorite_page_id
          ];
      });
      assetSiteLists?.sites?.forEach(sitesObj => {
        favoriteLists[sitesObj.favorite_page_id] =
          folderState?.[sitesObj.parentId]?.favPageId?.[
            sitesObj.favorite_page_id
          ];
      });
      assetSiteLists?.trends?.forEach(trendsObj => {
        favoriteLists[trendsObj.favorite_page_id] =
          folderState?.[trendsObj.parentId]?.favPageId?.[
            trendsObj.favorite_page_id
          ];
      });
      setFvSiteAssetOpen(favoriteLists);
    }, [assetSiteLists]);

    const handleSiteFavChange = panel => newExpanded => {
      setSiteFavExpanded(newExpanded ? panel : false);
    };
    const handleAssetFavChange = panel => newExpanded => {
      setAssetFavExpanded(newExpanded ? panel : false);
    };
    const handleTrendFavChange = panel => newExpanded => {
      setfavTrendExpanded(newExpanded ? panel : false);
    };
    const handleEnableOrDisableSitesAssets = (obj, folder) => {
      setFvSiteAssetOpen({ ...fvSiteAssetOpen, [obj]: !fvSiteAssetOpen[obj] });
      setFolderState({
        ...folderState,
        [folder]: {
          ...folderState?.[folder],
          favPageId: {
            ...folderState?.[folder]?.favPageId,
            [obj]: !folderState?.[folder]?.favPageId?.[obj],
          },
        },
      });
    };
    const sitesFilteredRows = favTableSitesObj(assetSiteLists?.sites);
    const assetsFilteredRows = favTableSitesObj(assetSiteLists?.assets);
    const trendsFilteredRows = favTableSitesObj(assetSiteLists?.trends);

    const formTrendsFavorites = trendLists => {
      const trendData = Object.keys(trendLists?.trends)?.length ? (
        <Box>
          <Table className={classes.mainPortfolioTable}>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Box className={classes.pageNameLable}>
                    {favoriteTransObj.portFolioTrend}
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box className={classes.rowPortFolioBox}>
                    <Table className={classes.mainPortTable}>
                      {sortBy(Object.keys(trendsFilteredRows))?.map(obj => (
                        <TableBody
                          key={`${trendsFilteredRows[obj][0]?.parentId}-${obj}`}
                        >
                          {obj && obj !== undefinedValue ? (
                            <TableRow>
                              <TableCell>
                                <Box
                                  onClick={() => handleTrendFavChange(obj)}
                                  className={classes.pageNameLable}
                                >
                                  {obj !== undefinedValue ? t(obj) : ''}
                                  <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    expanded={favTrendExpanded === obj}
                                    onClick={() =>
                                      handleEnableOrDisableSitesAssets(
                                        obj,
                                        trendsFilteredRows[obj][0]?.parentId,
                                      )
                                    }
                                  >
                                    {fvSiteAssetOpen[obj] ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ) : (
                            ''
                          )}
                          {trendsFilteredRows[obj]?.map(inObj => {
                            const trendCheckBoxId = `${inObj?.trend_link}`;
                            const rowTrends = formLableforPortfolioTrends(
                              inObj,
                              favoriteTransObj,
                            );
                            return (
                              <TableRow key={inObj?.site_uuid}>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={4}
                                >
                                  <Collapse
                                    in={fvSiteAssetOpen[obj]}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <Box className={classes.rowBox}>
                                      <Table size="small">
                                        <TableBody>
                                          <TableRow>
                                            <TableCell scope="row">
                                              <Box
                                                display="flex"
                                                className={
                                                  classes.firstColPortfolo
                                                }
                                              >
                                                <Box
                                                  mt={1}
                                                  mr={2}
                                                  className={
                                                    classes.deleteLabel
                                                  }
                                                  title={
                                                    favoriteTransObj.deleteRow
                                                  }
                                                  onClick={e =>
                                                    deleteRows(
                                                      e,
                                                      inObj,
                                                      folderState?.[
                                                        folderObj.folderName
                                                      ]?.assets[
                                                        trendCheckBoxId
                                                      ],
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon
                                                    className={clsx(
                                                      classes.deleteIcon,
                                                      {
                                                        [classes.disabledFilters]:
                                                          !folderState?.[
                                                            folderObj.folderName
                                                          ]?.assets[
                                                            trendCheckBoxId
                                                          ],
                                                      },
                                                    )}
                                                  />
                                                </Box>
                                                <Box>
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        name={trendCheckBoxId}
                                                        checked={
                                                          folderState?.[
                                                            folderObj.folderName
                                                          ]?.assets[
                                                            trendCheckBoxId
                                                          ]
                                                        }
                                                        color="primary"
                                                      />
                                                    }
                                                    onClick={e =>
                                                      selectParentAndChildRows(
                                                        e,
                                                        folderObj?.folderName,
                                                        trendCheckBoxId,
                                                      )
                                                    }
                                                  />
                                                </Box>
                                                <Box
                                                  className={classes.urlLabel}
                                                  mt={1.5}
                                                  onClick={() => {
                                                    if (inObj.type) {
                                                      history(
                                                        constructPaiUrlText(
                                                          inObj,
                                                        ),
                                                      );
                                                    }
                                                  }}
                                                >
                                                  {rowTrends}
                                                </Box>
                                              </Box>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      ))}
                    </Table>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      ) : null;
      return trendData;
    };
    const formSitesFavorites = siteLists => {
      const siteData = Object.keys(siteLists?.sites)?.length ? (
        <Box className={classes.rowSiteBox}>
          <Table className={classes.mainSiteTable}>
            <TableHead>
              <TableRow>
                <TableCell>{favoriteTransObj.site}</TableCell>
                <TableCell>{favoriteTransObj.subscription}</TableCell>
                <TableCell>{favoriteTransObj.organization}</TableCell>
              </TableRow>
            </TableHead>
            {Object.keys(sitesFilteredRows)?.map(obj => (
              <TableBody key={`${sitesFilteredRows[obj][0]?.parentId}-${obj}`}>
                {obj && obj !== undefinedValue ? (
                  <TableRow>
                    <TableCell>
                      <Box
                        onClick={() => handleSiteFavChange(obj)}
                        className={classes.pageNameLable}
                      >
                        {obj !== undefinedValue ? t(obj) : ''}
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          expanded={favSiteExpanded === obj}
                          onClick={() =>
                            handleEnableOrDisableSitesAssets(
                              obj,
                              sitesFilteredRows[obj][0]?.parentId,
                            )
                          }
                        >
                          {fvSiteAssetOpen[obj] ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  ''
                )}
                {sitesFilteredRows[obj]?.map(inObj => {
                  const siteCheckBoxId = `${inObj?.site_uuid}-${inObj?.favorite_page_id}`;
                  return (
                    <TableRow key={inObj?.site_uuid}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={4}
                      >
                        <Collapse
                          in={fvSiteAssetOpen[obj]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box className={classes.rowBox}>
                            <Table size="small">
                              <TableBody>
                                <TableRow>
                                  <TableCell scope="row">
                                    <Box
                                      display="flex"
                                      className={classes.firstColFav}
                                    >
                                      <Box
                                        mt={1}
                                        mr={2}
                                        className={classes.deleteLabel}
                                        title={favoriteTransObj.deleteRow}
                                        onClick={e =>
                                          deleteRows(
                                            e,
                                            inObj,
                                            folderState?.[folderObj.folderName]
                                              ?.assets[siteCheckBoxId],
                                          )
                                        }
                                      >
                                        <DeleteIcon
                                          className={clsx(classes.deleteIcon, {
                                            [classes.disabledFilters]:
                                              !folderState?.[
                                                folderObj.folderName
                                              ]?.assets[siteCheckBoxId],
                                          })}
                                        />
                                      </Box>
                                      <Box>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name={siteCheckBoxId}
                                              checked={
                                                folderState?.[
                                                  folderObj.folderName
                                                ]?.assets[siteCheckBoxId]
                                              }
                                              color="primary"
                                            />
                                          }
                                          onClick={e =>
                                            selectParentAndChildRows(
                                              e,
                                              folderObj?.folderName,
                                              siteCheckBoxId,
                                            )
                                          }
                                        />
                                      </Box>
                                      <Box
                                        className={classes.urlLabel}
                                        mt={1.5}
                                        onClick={() => {
                                          if (inObj.type) {
                                            history(constructPaiUrlText(inObj));
                                          }
                                        }}
                                      >
                                        {t(inObj?.name)}
                                      </Box>
                                    </Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box>{inObj?.contract_code}</Box>
                                  </TableCell>
                                  <TableCell>
                                    <Box>{inObj?.organization_name}</Box>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ))}
          </Table>
        </Box>
      ) : null;
      return siteData;
    };
    const formAssetsFavorites = assetLists => {
      const assetData = Object.keys(assetLists?.assets)?.length ? (
        <Box className={classes.rowAssetBox}>
          <Table className={classes.mainAssetTable}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableTd}>
                  {favoriteTransObj.equipment}
                </TableCell>
                <TableCell align="left">{favoriteTransObj.site}</TableCell>
                <TableCell align="center">
                  {favoriteTransObj.subscription}
                </TableCell>
                <TableCell align="center">
                  {favoriteTransObj.organization}
                </TableCell>
              </TableRow>
            </TableHead>
            {Object.keys(assetsFilteredRows)?.map(obj => (
              <TableBody>
                <TableRow className={classes.root}>
                  <TableCell>
                    <Box onClick={() => handleAssetFavChange(obj)}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        expanded={favAssetExpanded === obj}
                        onClick={() =>
                          handleEnableOrDisableSitesAssets(
                            obj,
                            assetsFilteredRows[obj][0]?.parentId,
                          )
                        }
                      ></IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
                {assetsFilteredRows[obj]?.map(inObj => {
                  const assetCheckBoxId = `${inObj?.asset_uuid}-${inObj?.favorite_page_id}`;
                  return (
                    <TableRow key={inObj?.asset_uuid}>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={5}
                      >
                        <Box className={classes.rowBox}>
                          <Table size="small">
                            <TableBody>
                              <TableRow>
                                <TableCell scope="row">
                                  <Box
                                    display="flex"
                                    className={classes.firstColFavAsset}
                                  >
                                    <Box
                                      mt={1}
                                      mr={2}
                                      className={classes.deleteLabel}
                                      title={favoriteTransObj.deleteRow}
                                      onClick={e =>
                                        deleteRows(
                                          e,
                                          inObj,
                                          folderState?.[folderObj.folderName]
                                            ?.assets[assetCheckBoxId],
                                        )
                                      }
                                    >
                                      <DeleteIcon
                                        className={clsx(classes.deleteIcon, {
                                          [classes.disabledFilters]:
                                            !folderState?.[folderObj.folderName]
                                              ?.assets[assetCheckBoxId],
                                        })}
                                      />
                                    </Box>
                                    <Box>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            name={assetCheckBoxId}
                                            checked={
                                              folderState?.[
                                                folderObj.folderName
                                              ]?.assets[assetCheckBoxId]
                                            }
                                            color="primary"
                                          />
                                        }
                                        onClick={e =>
                                          selectParentAndChildRows(
                                            e,
                                            folderObj?.folderName,
                                            assetCheckBoxId,
                                          )
                                        }
                                      />
                                    </Box>
                                    <Box
                                      className={classes.urlLabel}
                                      mt={1.5}
                                      onClick={() => {
                                        if (inObj.type) {
                                          history(constructPaiUrlText(inObj));
                                        }
                                      }}
                                    >
                                      {t(inObj?.name)}
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell>{inObj?.site_name}</TableCell>
                                <TableCell>{inObj?.contract_code}</TableCell>
                                <TableCell>
                                  {inObj?.organization_name}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ))}
          </Table>
        </Box>
      ) : null;
      return assetData;
    };

    return (
      <Box>
        {formTrendsFavorites(assetSiteLists)}
        {formSitesFavorites(assetSiteLists)}
        {formAssetsFavorites(assetSiteLists)}
      </Box>
    );
  };
  FavTableRows.propTypes = {
    assetSiteLists: PropTypes.object,
    folderObj: PropTypes.object,
  };

  const selectParentAndChildRows = (e, folderName, assetSiteId = '') => {
    e.stopPropagation();
    if (assetSiteId) {
      const totalAssets = Object.keys(folderState[folderName]?.assets)?.length;
      const selectedAssets = Object.keys(
        folderState[folderName]?.assets,
      )?.filter(o => folderState[folderName]?.assets[o])?.length;

      const updatedFolderState = {
        ...folderState[folderName],
        status: e.target.checked && totalAssets - 1 === selectedAssets,
        assets: {
          ...folderState[folderName]?.assets,
          [assetSiteId]: e.target.checked,
        },
      };
      setFolderState({ ...folderState, [folderName]: updatedFolderState });
    } else {
      const updatedFolderState = {
        ...folderState[folderName],
        status: e.target.checked,
        assets: mapValues(
          folderState[folderName]?.assets,
          () => e.target.checked,
        ),
      };
      setFolderState({ ...folderState, [folderName]: updatedFolderState });
    }
  };

  const formLableforPortfolioTrends = (rowData, favoriteTransObj) => {
    let lableValue = '';
    switch (rowData?.favorite_page_id) {
      case type.PORTFOLIO_TREND:
        lableValue = `${rowData?.group_id?.split(trendsFavSplitter)[1]} ${
          rowData?.bundle_name
        } ${favoriteTransObj.portFolioTrendReport} (${capitalize(
          rowData?.trend_link?.split(trendsFavSplitter)[2],
        )})`;

        break;
      case type.PORTFOLIO_TREND_REPORT:
        lableValue = `${rowData?.group_id?.split(trendsFavSplitter)[1]} ${
          rowData?.bundle_name
        }
        ${rowData?.combo_code} (${capitalize(
          rowData?.trend_link?.split(trendsFavSplitter)[2],
        )})`;
        break;
      default:
        lableValue = '';
    }
    return lableValue;
  };

  const constructPaiUrlText = rowData => {
    let returnUrl;
    let trendReportURL = '';
    if (rowData?.favorite_page_id === type.PORTFOLIO_TREND_REPORT) {
      const trendLink = rowData?.trend_link?.split(trendsFavSplitter);
      const groupId = rowData?.group_id?.split(trendsFavSplitter)[0];
      trendReportURL = encodeURI(
        `/${trendLink?.[0]}/trends-view?assetCat=${trendLink?.[1]}&viewBy=${trendLink?.[2]}&comboCode=${trendLink?.[3]}&groupId=${groupId}`,
      );
    }
    switch (rowData?.favorite_page_id) {
      case type.TODO_LIST:
        returnUrl = encodeURI(
          `/${rowData.site_uuid}/${rowData.contract_code}/site-details/pai/active`,
        );
        break;
      case type.SITE_SUMMARY:
        returnUrl = encodeURI(
          `/${rowData.site_uuid}/${rowData.contract_code}/site-details/ais/active`,
        );
        break;
      case type.Temperature_and_consumption_view:
        returnUrl = encodeURI(
          `/${rowData.site_uuid}/${rowData.contract_code}/temperature-and-consumption`,
        );
        break;
      case type.EQUIPMENT:
        returnUrl = encodeURI(
          `/${rowData.contract_code}/${rowData.asset_uuid}/equipment-details/pai/details`,
        );
        break;
      case type.PORTFOLIO_TREND:
        returnUrl = encodeURI(
          `/${rowData?.bundle_id}/trends-view?groupId=${
            rowData?.group_id?.split(trendsFavSplitter)[0]
          }&viewBy=${rowData?.trend_link?.split(trendsFavSplitter)[2]}`,
        );
        break;
      case type.PORTFOLIO_TREND_REPORT:
        returnUrl = trendReportURL;
        break;
      default:
        returnUrl = '';
    }
    return returnUrl;
  };

  const deleteRows = useCallback((e, row, status) => {
    e.stopPropagation();
    if (status) {
      setRowToDelete([row]);
      setOpen(true);
    }
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={tableTheme(props?.size)}>
        <Box>
          <ConfirmDialog
            open={open}
            setOpen={setOpen}
            rowToDelete={rowToDelete}
            deleteFavorites={props?.deleteFavorites}
            favoriteLoading={props?.favoriteLoading}
            favoriteTransObj={props.favoriteTransObj}
            loaderImage={props.loaderImage}
          />
        </Box>
        <Box className={classes.tableBox}>
          {favoriteTableData?.map(fvObj => (
            <Accordion
              square
              expanded={fvexpanded === fvObj?.folderName}
              onChange={handleFolderChange(fvObj?.folderName)}
              key={fvObj?.folderName}
              className={classes.mainAcc}
            >
              <AccordionSummary
                aria-controls="panel1d-conten1t"
                data-qa-id="favorites-wrapper"
                className={classes.accTitle}
              >
                <Box
                  display="flex"
                  data-qa-id="favorites-folder-wrapper"
                  style={{ width: '100%' }}
                  onClick={() => setfoldOpen(!foldOpen)}
                >
                  <Box mt={1} mr={2}>
                    {foldOpen && fvexpanded === fvObj?.folderName ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </Box>

                  <Box
                    mt={1}
                    mr={2}
                    data-qa-id="favorites-multiDelete-wrapper"
                    onClick={e =>
                      deleteRows(
                        e,
                        fvObj,
                        folderState?.[fvObj?.folderName]?.status,
                      )
                    }
                  >
                    <DeleteIcon
                      className={clsx(classes.deleteIcon, {
                        [classes.disabledFilters]:
                          !folderState?.[fvObj?.folderName]?.status,
                      })}
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={fvObj?.folderName}
                          data-qa-id="favorites-multi-checkBoxfolder-wrapper"
                          checked={folderState?.[fvObj?.folderName]?.status}
                          color="primary"
                        />
                      }
                      onClick={e =>
                        selectParentAndChildRows(e, fvObj?.folderName, '')
                      }
                    />
                  </Box>
                  <Box mt={1.5} className={classes.folderAcc}>{`${
                    fvObj?.folderName
                  } (${
                    fvObj?.assetSiteLists?.assets?.length +
                    fvObj?.assetSiteLists?.sites?.length +
                    fvObj?.assetSiteLists?.trends?.length
                  })`}</Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accDetails}>
                <Box className={classes.accBox}>
                  <FavTableRows
                    assetSiteLists={fvObj?.assetSiteLists}
                    folderObj={fvObj}
                    favoriteTransObj={props?.favoriteTransObj}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

FavoriteTableTree.propTypes = {
  data: PropTypes.array.isRequired,
  deleteFavorites: PropTypes.func,
  favoriteLoading: PropTypes.bool,
  favoriteTransObj: PropTypes.object,
  isFavoritePage: PropTypes.bool,
  loaderImage: PropTypes.string,
  size: PropTypes.oneOf(['large', 'small', 'x-small']),
};

FavoriteTableTree.defaultProps = {
  size: 'large',
  isFavoritePage: false,
};

export default React.memo(FavoriteTableTree);
