import { enUS, es, zhCN, fr, de, nl, it, arSA, tr } from 'date-fns/locale';
import { ScalerFilter } from './utils/numberFilter';
import { TextFilter } from './utils/textFilter';
// Route names
export const routes = Object.freeze({
  SEARCH_PAGE: 'SEARCH_PAGE',
  RAW_DATA_PAGE: 'RAW_DATA_PAGE',
  SETTINGS_PAGE: 'SETTINGS_PAGE',
  LOGOUT_PAGE: 'LOGOUT_PAGE',
  ASSET_DETAIL_PAGE: 'ASSET_DETAIL_PAGE',
});

export const APP_BAR_HEIGHT = 40;
export const ACTION_BAR_HEIGHT = 40;
export const APP_BAR_HEIGHT_SM = 50;
export const BOTTOM_NAV_HEIGHT = 56;
export const MOBILE_SITE_POINT_VIEW_HEIGHT = 54;
export const BREADCRUMB_HEIGHT = 30;
export const PAGE_HEADER_HEIGHT = 80;
export const STATUS_LABEL_HEIGHT = 157;
export const TIMELINE_STATUS_LABEL_HEIGHT = 52;
export const FILTER_HEIGHT = 45;
export const STATUS_BAR_HEIGHT = 45;
export const STATUS_FILTER_HEIGHT = 42;
export const REFRESH_NEEDED = 'REFRESH_NEEDED';
export const RECENTLY_VIEWED_MAX_DAYS = 3;
export const DELIMITER = '_=_';
export const SINGLE_SITE_USER_TYPE = 'Single-Service-Customer';
export const MULTI_SITE_USER_TYPE = 'Multi-Service-Customer';
export const EQUIPMENT_HISTORY_PAGE = 'equipmentHistory';
export const EQUIPMENT_DETAILS_PAGE = 'equipmentDetails';
export const PORTFOLIO_CATEGORY = 'EI';
export const PORTFOLIO_PERFORMANCE_CATEGORY = 'EPI';
export const PORTFOLIO_INVENTORY_CATEGORY = 'EII';
export const SITE_LIST_SUM_NODE = 'sum';
export const PORTFOLIO_SUM_NODE = 'summary';

export const sortTypes = Object.freeze({
  text: 'text',
  numeric: 'numeric',
});

export const defaultFilterTypes = Object.freeze({
  [sortTypes.text]: TextFilter.EQUALS,
  [sortTypes.numeric]: ScalerFilter.EQUALS,
});

export const nonPointKeys = Object.freeze({
  system: 'system',
  number: 'number',
  desc: 'desc',
  time: 'time',
});

export const sortOrders = Object.freeze({
  DESCENDING: 'DESCENDING',
  ASCENDING: 'ASCENDING',
});

export const FilterSymbols = Object.freeze({
  [ScalerFilter.EQUALS]: '=',
  [ScalerFilter.NOT_EQUAL]: '!=',
  [ScalerFilter.LESS_THAN]: '<',
  [ScalerFilter.LESS_THAN_OR_EQUAL]: '≤',
  [ScalerFilter.GREATER_THAN]: '>',
  [ScalerFilter.GREATER_THAN_OR_EQUAL]: '≥',
});

export const BREADCRUMB_LABELS = Object.freeze({
  HOME: 'Home',
  SEARCH: 'Search',
  SITE: 'Site_Details',
  EQUIPMENT: 'Equipment_Details',
  SITE_LIST: 'Site_List',
  INVENTORY_SITE_LIST: 'Inventory_Site_List',
  TRENDS_VIEW: 'Portfolio_Trend',
  TEMPERATURE_AND_CONSUMPTION: 'Temperature_and_consumption_view',
});

export const assetDetailsUrls = Object.freeze({
  details: 'details',
  history: 'history',
});

// Labels mapping with searchParams
export const searchParams = {
  SiteCode: 'filter',
  SiteName: 'siteName',
  City: 'city',
  State: 'state',
  Zip: 'zipcode',
  Country: 'country',
  OrganizationCode: 'org',
};

export const searchReverseParams = {
  filter: 'SiteCode',
  siteName: 'SiteName',
  city: 'City',
  state: 'State',
  zipcode: 'Zip',
  country: 'Country',
  org: 'OrganizationCode',
};

export const urgency = Object.freeze({
  URGENT: 'URGENT',
  PLAN: 'PLAN',
  NONE: 'NONE',
  HOLD: 'HOLD',
});

export const HAVE_RECOMMENDATIONS = 'Recommendation';
export const NO_RECOMMENDATIONS = 'No_Recommendation';
export const ACTIVITY_PRESENT = 'Activity_present';
export const NO_ACTIVITY_PRESENT = 'No_activity_present';
export const BOOKMARKED = 'Bookmarked';
export const NOT_BOOKMARKED = 'Not_Bookmarked';
export const FIELD = 'Field Visit';
export const REMOTE = 'Remote Fix';

export const DEFAULT_FAVORITE_FOLDER = 'My Favorite';
export const MAX_NUMBER_OF_FAVORITE_FOLDER = 10;
export const MAX_NUMBER_OF_ALLOWABLE_FAVORITES_IN_A_FOLDER = 20;
export const MAX_LENGTH_OF_INPUT = 32;
export const MAX_LENGTH_OF_JSON = 800000;

export const defaultDays = 90;
export const offsetDays = 179; // Considering today into consideration, allowing total of 90 days to be selected by the user
export const defaultDaysForSiteSummaryDialog = 90; // test with 90 to verify data display

export const unitCategory = Object.freeze({
  unit1: 'unit1',
  unit2: 'unit2',
});

export const siteDetailRoutes = Object.freeze({
  active: 'active',
  hold: 'hold',
  history: 'history',
  bookmark: 'bookmark',
});

export const resolutionTypeTexts = Object.freeze({
  none: 'None',
  fieldVisit: 'Field Visit',
  remoteFix: 'Remote Fix',
  both: 'Both Remote & Field visit',
});

export const resolutionTypeFilters = Object.freeze({
  field: 'Field',
  remote: 'Remote',
  both: 'RemoteFieldBoth',
});

export const paiFeedbackAssetCount = 2;

export const sectionNameListMapping = {
  all: 'all',
  sources: 'fbSrc',
  workstatus: 'workSts',
  noIssueExists: 'noIssueExists',
  recommendations: 'userRecomms',
  comments: 'userComments',
  parts: 'parts',
};

export const resolutionTypeTextsWithResponseIds = Object.freeze({
  FLD: { id: 'Field Visit', label: 'Field_Visit' },
  REM: { id: 'Remote Fix', label: 'Remote_Fix' },
  // REMOT: 'Remote Fix',
  NONE: { id: 'None', label: 'None' },
});

export const workOrderStatusIdMapping = Object.freeze({
  completed: 'COMPLETED',
  inProgress: 'INPROGRESS',
  inPro: 'INPRO',
});

export const partsIdMapping = Object.freeze({
  planned: 'PLAN',
  replaced: 'REPL',
});

export const recommedationIdMapping = Object.freeze({
  confirm: 'CONFIRM',
  none: 'NONE',
});

export const workOrderStatusDnMapping = Object.freeze({
  completed: 'Completed',
  inProgress: 'In Progress',
  inPro: 'In Progress Update',
});

export const workOrderStatusIdToDnMapping = Object.freeze({
  COMPLETED: 'Completed',
  INPROGRESS: 'In Progress',
  INPRO: 'In Progress',
});

export const workOrderStatusLabelsMapping = Object.freeze({
  Completed: 'Completed',
  'In Progress': 'In_Progress',
});

export const workOrderStatusLabelsMappingUpperCase = Object.freeze({
  Completed: 'COMPLETED',
  'In Progress': 'IN_PROGRESS',
});

export const benefitTypesPosMapping = Object.freeze({
  CMFRT: 1,
  'H&S': 2,
  RFS: 3,
  MAINTC: 4,
  MAINTP: 5,
  EE: 6,
  CONN: 7,
  DCONN: 8,
  CNTRL_PERF: 9,
  PERF: 10,
});

export const ppRegexFilter = /^__D/;

export const CONFIRM_TEXT = 'confirm';

export const CATEGORY_CODE_DELIMITER = '#==#';

export const mapSectionWithAPIEndpoint = {
  sources: '/source',
  comments: '/comments',
  workstatus: '/workstatus',
  recommendations: '/recommendations',
  parts: '/parts',
  noIssueExists: '',
};

/* EcoEnergy-Anish Davy */
export const defaultBarWidth = 17;
export const TEMP_CONSUMP_KEY = 'tempConsump';
export const startInPage = [
  { key: 'todoList', value: 'To-do_list' },
  { key: 'siteSummary', value: 'Site_Summary' },
  { key: 'tempConsump', value: 'Temperature_and_consumption_view' },
];
export const initialStartIn = 'todoList';
export const dataView = {
  todoList: 'pai',
  siteSummary: 'ais',
  tempConsump: 'tempConsump',
};
export const assetSummary = {
  URGENT: {
    color: '#F5841F',
    bg: '#FFF8F2',
    state: 'URGENT',
  },
  OUTOFDATE: {
    color: '#F5841F',
    bg: '#FFF8F2',
    state: 'OUTOFDATE',
  },
  CRITICAL: {
    color: '#F5841F',
    bg: '#FFF8F2',
    state: 'CRITICAL',
  },
  ATN_REQ: {
    color: '#F5841F',
    bg: '#FFF8F2',
    state: 'URGENT',
  },
  NODATA: {
    color: '#A6A6A6',
    bg: '#F7F8F9',
    state: 'NODATA',
  },
  'NODATA-INV': {
    color: '#A6A6A6',
    bg: '#F7F8F9',
    state: 'NODATA-INV',
  },
  'NOT-COMM': {
    color: '#A6A6A6',
    bg: '#F7F8F9',
    state: 'NODATA',
  },
  HOLD: {
    color: '#A6A6A6',
    bg: '#F7F8F9',
    state: 'NODATA',
  },
  GOOD: {
    color: '#8DC63F',
    bg: '#F8FCF4',
    state: 'GOOD',
  },
  UPTODATE: {
    color: '#8DC63F',
    bg: '#F8FCF4',
    state: 'UPTODATE',
  },
  NONE: {
    color: '#8DC63F',
    bg: '#F8FCF4',
    state: 'GOOD',
  },
  PLAN: {
    color: '#FFE552',
    bg: '#FFFEF5',
    state: 'PLAN',
  },
  UPDAVBL: {
    color: '#FFE552',
    bg: '#FFFEF5',
    state: 'UPDAVBL',
  },
  'NOT-CONN': {
    color: '#8FAADC',
    bg: '#F9FCFE',
    state: 'NOT-CONN',
  },
  'NOT-CONN-INV': {
    color: '#8FAADC',
    bg: '#F9FCFE',
    state: 'NOT-CONN-INV',
  },
  'ATN-REQ': {
    color: '#F5841F',
    bg: '#FFF8F2',
    state: 'URGENT',
  },
};
export const availableAsset = ['GOOD', 'URGENT', 'PLAN', 'NOT-CONN', 'NODATA'];

export const equipmentStatusForReports = [
  'URGENT',
  'NOT-CONN',
  'NODATA',
  'GOOD',
  'PLAN',
];
export const sortOrder = Object.freeze({
  GOOD: 0,
  URGENT: 1,
  CRITICAL: 2,
  PLAN: 3,
  'NOT-CONN': 4,
  NODATA: 5,
  ONB: 6,
  CONN: 7,
});

// All possible values for Asset summary for inventory
export const INVENTORY_ASSET_SUM = [
  'UPTODATE',
  'OUTOFDATE',
  'UPDAVBL',
  'NODATA-INV',
  'ONB-INV',
  'NOT-CONN-INV',
  'CONN-INV',
];

export const sortOrderInventory = Object.freeze({
  UPTODATE: 0,
  OUTOFDATE: 1,
  UPDAVBL: 2,
  'NOT-CONN-INV': 3,
  'NODATA-INV': 4,
  'ONB-INV': 5,
  'CONN-INV': 6,
});

export const notServicedErrorMessages = {
  'Not-Serviced': 'We_are_sorry,_we_are_not_giving_any_service_to_you_yet',
  'Not-Serviced(Orgnization Association Pending)':
    'Organization_Association_Pending',
  'Not-Serviced(Orgnization Association Pending Approval)':
    'Organization_Association_Pending_Approval',
  'Not-Serviced(User Role Association Pending - Contact User Administrator)':
    'User_Role_Association Pending_-_Contact_User_Administrator',
  'Not-Serviced(Site Association Pending - Contact User Administrator)':
    'Site_Association_Pending_-_Contact_User_Administrator',
  'Not-Serviced(Your account is being setup please try after some time)':
    'Your_account_is_being_setup,_please_try_after_some_time',
};

export const associationPending =
  'Not-Serviced(Orgnization Association Pending)';

export const DEFAULT_LOCALE_LANGUAGE = { code: 'en', label: 'English' };

export const filtersConstant = {
  ageInDays: 'ageInDays',
  haveRecommendations: 'haveRecommendations',
  assetCategory: 'assetCategory',
  benefits: 'benefits',
  condition: 'condition',
  resolutionType: 'resolutionType',
  urgency: 'urgency',
  workInProgress: 'workInProgress',
  activities: 'activities',
  workOrder: 'workOrder',
};

export const customBreadcrumbs = {
  siteList: [
    { key: '/home', label: BREADCRUMB_LABELS.HOME },
    {
      key: '/site-list',
      label: BREADCRUMB_LABELS.SITE_LIST,
    },
  ],
  inventorySitList: [
    { key: '/home', label: BREADCRUMB_LABELS.HOME },
    {
      key: '/inventory-site-list',
      label: BREADCRUMB_LABELS.INVENTORY_SITE_LIST,
    },
  ],
  trends: [
    { key: '/home', label: BREADCRUMB_LABELS.HOME },
    {
      key: '/trends-view',
      label: BREADCRUMB_LABELS.TRENDS_VIEW,
    },
  ],
  temperature: [
    { key: '/home', label: BREADCRUMB_LABELS.HOME },
    { key: '/search', label: BREADCRUMB_LABELS.SEARCH },
    {
      key: '/temperature-and-consumption',
      label: BREADCRUMB_LABELS.TEMPERATURE_AND_CONSUMPTION,
    },
  ],
};

export const assetSortType = {
  desc: 'High_to_low',
  asc: 'Low_to_high',
};

export const REC_BY_AI = 'AI';

export const defaultReason = [
  { src: { id: 'PROACT-REQ', dn: 'Proactive Request' }, srcId: '' },
];

export const availability = 'AVAILABILITY';
export const trendViewBy = {
  day: {
    label: 'Day',
    view: 'Daily',
    plural: 'Days',
    divider: 1,
    default: 89,
  },
  week: {
    label: 'Week',
    view: 'Weekly',
    plural: 'Weeks',
    divider: 7,
    default: 364,
    weekRange: 6,
  },
  month: {
    label: 'Month',
    view: 'Monthly',
    plural: 'Months',
    divider: 30,
    default: 729,
  },
};
export const defaultTrendViewBy = 'week';
export const monthTrend = 'month';
export const RTL_LANGUAGES = ['ar'];

export const pdfReportTypes = [
  'Site summary',
  'All urgent equipment',
  'All urgent and plan equipment',
  'All equipment',
  'Maintenance',
];

export const pdfReportTypesMap = {
  'Site summary': 'site-summary-all',
  'All urgent equipment': 'site-summary-all-urgent',
  Maintenance: 'maintenance-summary',
};

export const parentComp = [
  'SiteDetails',
  'ReportsDialog',
  'EquipmentsReport',
  'SiteSummary',
];

export const INVENTORY_LEGEND_COLOR_MAP = {
  GOOD: '#8DC63F',
  UPTODATE: '#8DC63F',
  NODATA: '#A6A6A6',
  'NODATA-INV': '#A6A6A6',
  'NOT-CONN': '#8FAADC',
  'NOT-CONN-INV': '#8FAADC',
  URGENT: '#F5841F',
  OUTOFDATE: '#F5841F',
  PLAN: '#FFE552',
  UPDAVBL: '#FFE552',
};

export const EXCLUDED_STATUS = [
  'CRITICAL',
  'ONB',
  'CONN',
  'ONB-INV',
  'CONN-INV',
];

export const DEFAULT_ASSET_FILTERS = ['Bavg', 'Avg', 'Good', 'Others'];

export const availableInventoryAsset = [
  'UPTODATE',
  'OUTOFDATE',
  'UPDAVBL',
  'NODATA-INV',
  'NOT-CONN-INV',
];

export const ASSET_FILTER_MAP = {
  OUTOFDATE: 'Bavg',
  UPDAVBL: 'Avg',
  UPTODATE: 'Good',
  'NODATA-INV': 'Others',
  'NOT-CONN-INV': 'Others',
};

export const INVENTORY_INSIGHTS_LABEL = 'Inventory_Insights';

export const benefitTypes = Object.freeze({
  CMFRT: 'CMFRT',
  'H&S': 'H&S',
  RFS: 'RFS',
  MAINTC: 'MAINTC',
  MAINTP: 'MAINTP',
  EE: 'EE',
  CONN: 'CONN',
  DCONN: 'DCONN',
  CNTRL_PERF: 'CNTRL_PERF',
});

export const colorVariations = {
  Bavg: '#f4811d',
  Avg: '#ffe551',
  Good: '#8fc740',
  Null: '#a9a9a9',
  NullN: '#a9a9a9',
};

export const trendsSplitter = '_#~';
export const trendsFavSplitter = '$$';
export const localeMap = {
  en: enUS,
  es,
  zh: zhCN,
  fr,
  de,
  nl,
  it,
  ar: arSA,
  tr,
};
export const STTMP_TEXT = 'STTMP';
// export const EULA_LINK = 'https://cortix.ai/public/CORTIX%20EULA%20031121.htm';
export const includeWorkOrderAndEvents = 'includeWorkOrderAndEvents';
export const remote = 'remote';
export const field = 'field';

export const supportedLanguages = {
  zh: { value: 'zh', fontFamily: '源真ゴシック' },
  ja: { value: 'ja', fontFamily: 'NotoSansJP' },
};

export const defaultFontFamily = 'customFont';

export const RESOLUTION_TYPE_MAP = {
  REM: {
    siteDetailfilterValue: ['Remote Fix', 'Both Remote & Field visit'],
    siteSumFilterValue: ['Remote', 'RemoteFieldBoth'],
  },
  FLD: {
    siteDetailfilterValue: ['Field Visit', 'Both Remote & Field visit'],
    siteSumFilterValue: ['Field', 'RemoteFieldBoth'],
  },
};
export const ALL_INFO_FILTER_PROFILE = 'ALL_INFO';
export const dafaultHistory = 'hr';
export const historyBy = {
  hr: { label: 'Hour', days: 7 },
  day: { label: 'Day', days: 30 },
  month: { label: 'Month', days: 730 },
};
export const historyFont = {
  page: 8,
  zoom: 12,
};
export const historyColor = ['#036fbf', '#518e01', '#0b4c74', '#e65c00'];
export const limitCount = 6;
export const historyUnit = Object.freeze({
  tem: 'TEMPERATURE',
  diffTem: 'DIFFERENTIAL TEMPERATURE',
});

export const reportTemplates = [
  /* { key: 'cortixTemplate', value: 'CORTIX_Template' }, */
  { key: 'aboundTemplate', value: 'Abound_Template' },
];
export const initialReportTemplate = 'cortixTemplate';

// Added as part of rebranding
export const aboundReportTemplate = 'aboundTemplate';

export const EXCLUDED_STATE_FOR_FILTER = ['CONN', 'ONB'];
export const siteHistoryUnits = ['CENTIGRADE', 'KILOPASACAL', 'LPS'];
export const SERIAL_NUM_PREFIX = 'S/N';

export const historyMergeColor = {
  10: '#27ECF4',
  11: '#40CEF4',
  12: '#1EB7FA',
  13: '#3865F2',
  14: '#0A4DA6',
  15: '#245974',
  20: '#AAF549',
  21: '#9CDA4D',
  22: '#0BBE28',
  23: '#00AB4D',
  24: '#77A538',
  25: '#34731A',
  30: '#B107FA',
  31: '#BF4EF0',
  32: '#9D2BBF',
  33: '#E50EA0',
  34: '#7A1697',
  35: '#5E048B',
  40: '#D76630',
  41: '#D87342',
  42: '#E5936A',
  43: '#EAA279',
  44: '#F4C0A1',
  45: '#F4CFB7',
  inActive: '#CCCCCC',
  root: ['#81ACD6', '#B2F34E', '#A32ABE', '#D8632F'],
};

export const insightStatus = { 'NOT-COMM': 'NOT-COMM' };

export const pdfChartsPaddingBottom = {
  en: 90,
  es: 90,
  zh: 70,
  fr: 75,
  de: 90,
  nl: 90,
  it: 75,
  ar: 220,
  tr: 90,
  ja: 70,
};
export const denoTypes = ['ONB_ASSET_COUNT', 'CONN_SITE_COUNT'];
export const defaultAdditionalFilterParams = {
  feedBackAndServiceNotes: false,
  workOrder: false,
  repairs: false,
  parts: false,
  events: false,
  withNoFeedBackAndServiceNotes: false,
  selection: { withRecommendations: true, withoutRecommendations: false },
  // dateRange: { start: '', end: '' },
  dateRange: {},
};

export const RECOMMENDATION_OPTIONS = [
  'withRecommendation',
  'withoutRecommendation',
];
export const EXCLUDED_PAI_FIELD = [
  'selection',
  'dateRange',
  // 'feedBackAndServiceNotes',
];

export const SITE_LIST_EXPORT_OPTIONS = [
  { key: 'equipmentList', value: 'All_equipment_list' },
  { key: 'urgentEquipmentList', value: 'All_urgent_equipment_list' },
];
export const SITE_LIST_FILTERED_EQ_OPTION = {
  key: 'filteredTodoList',
  value: 'Filtered_equipment_To-do_list',
};

export const SITE_LIST_REPORT_OPTION = {
  key: 'siteList',
  value: 'Site_list',
};

export const URGENT_REPORT_CODE = 'URGENT';

export const NOT_AVAILABLE = 'NA';

export const defaultPaiForReport = {
  selection: { withRecommendations: true, withoutRecommendations: false },
  workOrder: false,
  repairs: false,
  parts: false,
  events: false,
  dateRange: { start: '', end: '' },
  withNoFeedBackAndServiceNotes: false,
};

export const SITE_LIST_REPORT_TYPE = 'SITE_LIST';
export const FILTER_EQ_TODO_LIST_REPORT_TYPE = 'EQ_TODO_LIST';
export const SITE_SUMMARY_REPORT_TYPE = 'SITE_SUM';

export const PREVIOUS_DOWNLOAD_POOLING_TIME = 2000;

export const WITHOUT_FEEDBACK_SERVICE_NOTES = {
  displayLabel: 'Equipment_without_any_feedback_and_service_notes_entry',
  name: 'withNoFeedBackAndServiceNotes',
};

export const FEEDBACK_AND_SERVICE_NOTES = {
  displayLabel: 'Include_feedback_and_service_notes_data',
  name: 'feedBackAndServiceNotes',
};
export const FILTER_SWITCH_PARAMS = [
  {
    displayLabel: 'With_work_orders',
    name: 'workOrder',
  },
  {
    displayLabel: 'With_repairs',
    name: 'repairs',
  },
  {
    displayLabel: 'With_parts',
    name: 'parts',
  },
  {
    displayLabel: 'With_events',
    name: 'events',
  },
];

export const ADDITIONAL_FILTER_SORT_ORDER = Object.freeze({
  feedBackAndServiceNotes: 0,
  withRecommendations: 1,
  withoutRecommendations: 2,
  workOrder: 3,
  repairs: 4,
  parts: 5,
  events: 6,
});

export const ADDITIONAL_FILTER_KEY_TEXT_MAP = {
  feedBackAndServiceNotes: 'Feedback_and_service_notes',
  withRecommendations: 'With_recommendations',
  withoutRecommendations: 'Without_recommendations',
  workOrder: 'workorder',
  repairs: 'repairs',
  parts: 'parts',
  events: 'events',
};

export const PREVIOUS_DOWNLOAD_DURATION = 48;
export const REQUEST_EXISTS_MSG = 'Request already exists';

export const REPORT_STATUS = {
  pending: 'pending',
  completed: 'completed',
  inProgress: 'in-progress',
  error: 'error',
};

export const comboCodeListFilterObj = {
  insightCategory: 'condition',
  PGMOBJ: 'benefits',
};
export const comboCodefilterTagsNames = [
  'insightFamily',
  'insightSubFamily',
  'PGMSOB',
];

export const sortOrderForSiteSummaryReport = Object.freeze({
  URGENT: 0,
  'NOT-CONN': 1,
  NODATA: 2,
  GOOD: 3,
  PLAN: 4,
});

export const exludedCategoryForIndividualEquipment = 'VAV';
export const siteCombicodeRowCount = 3;

// reference file for colors - react-common-component - theme.js, StatusChip
export const stageColor = {
  inprogress: {
    bgColor: '#ffc107',
  },
  urgent: {
    bgColor: '#FFC000',
  },
  plan: {
    bgColor: '#FCFC01',
  },
  hold: {
    bgColor: '#AEAEAE',
  },
  none: {
    bgColor: '#88C457',
  },
  open: {
    bgColor: 'rgba(140, 140, 140, 0.6)',
  },
  closed: {
    bgColor: 'rgb(76, 175, 80)',
  },
  confirmed: {
    bgColor: '#00968833',
    border: `1px solid`,
  },
  empty: { bgColor: '#ECECEC' },
};

export const pdfHistCompType = ['recom', 'repairs'];
export const parameterHistoryColor = [
  '#036fbf',
  '#518e01',
  '#0b4c74',
  '#e65c00',
  '#81ACD6',
  '#B2F34E',
  '#A32ABE',
  '#D8632F',
  '#5E048B',
  '#0A4DA6',
];
export const userRoles = Object.freeze({
  phAdvanced: 'DATA_VIS_ACC_ADV',
  tcAdvanced: 'TCADV',
});
export const PREV_DOWNLOAD_COUNT = 30;
export const uomMap = {
  TEMPERATURE: 'CENTIGRADE',
  'DIFFERENTIAL TEMPERATURE': 'CENTIGRADE',
  'FLOW LIQUID': 'LPS',
  PRESSURE: 'KILOPASACAL',
};
export const hashSplit = '####';

export const favoritePageIds = {
  'site-details/pai/active': {
    value: 'TODO_LIST',
    key: 'sites',
    displayName: 'To_do_list',
  },
  'site-details/ais/active': {
    value: 'SITE_SUMMARY',
    key: 'sites',
    displayName: 'Site_Summary',
  },
  'equipment-details/pai/details': {
    value: 'EQUIPMENT',
    key: 'assets',
    displayName: 'Equipment',
  },
  'trends-view': {
    value: 'PORTFOLIO_TREND',
    key: 'trends',
    displayName: 'Portfolio Trend Home',
  },
  'trends-view?assetCat': {
    value: 'PORTFOLIO_TREND_REPORT',
    key: 'trends',
    displayName: 'Portfolio Trend Report',
  },
  'temperature-and-consumption': {
    value: 'Temperature_and_consumption_view',
    key: 'sites',
    displayName: 'Temperature_and_consumption_view',
  },
};
export const favoriteCombiCodePageIds = {
  value: 'PORTFOLIO_TREND_REPORT',
  key: 'trends',
  displayName: 'Portfolio Trend Report',
  'temperature-and-consumption': {
    value: 'Temperature_and_consumption_view',
    key: 'sites',
    displayName: 'Temperature_and_consumption_view',
  },
};
// app restriction
export const enableAppRestrictionPaiPortal = true;
export const localStorageAllowedApps = 'allowedApps';
export const applicationCode = {
  pai: 'PAI',
  liveConnect: 'Live Connect',
  digitalService: 'Digital Service',
  tc: 'TC',
};
export const TEMP_LINK = 'temperatureAndConsumptionLink';
export const sidebarListId = { siteSummary: 'siteSummary' };
export const applicationUrls = {
  'Live Connect': '/liveConnect/search',
  'Digital Service': '/ds/home',
};
export const favoritePageNames = {
  favSiteSummary: 'SITE_SUMMARY',
  favTodoList: 'TODO_LIST',
  favEquipment: 'EQUIPMENT',
  favPortFolioTrend: 'PORTFOLIO_TREND',
  favPortFolioTrendReport: 'PORTFOLIO_TREND_REPORT',
  favTC: 'Temperature_and_consumption_view',
};
export const enableFeatures = { trendsCSVDownload: true };

export const toDolistNavSubMenuLists = [
  {
    id: 'siteDetailsActionableList',
    label: 'To_do_list',
    toolTipMsg: 'View_all_Active_(Urgent_and_Plan)_Actionables',
    disabled: false,
    hidden: false,
  },
  {
    id: 'siteDetailsActionableHistory',
    label: 'History',
    toolTipMsg: 'View_History_of_Actionables',
    disabled: false,
    hidden: false,
  },
  {
    id: 'siteDetailsActionableHold',
    label: 'Hold',
    toolTipMsg: 'View_Actionables_on_hold',
    disabled: false,
    hidden: false,
  },
  {
    id: 'siteDetailsActionableBookmark',
    label: 'Bookmarked',
    toolTipMsg: 'View_all_bookmarked_Actionables',
    disabled: false,
    hidden: false,
  },
];
export const privacyNoticeURL =
  'https://www.corporate.carrier.com/legal/privacy-notice/';
export const pdfLastPageMarginBottom = {
  en: 180,
  es: 120,
  zh: 60,
  fr: 180,
  de: 180,
  nl: 180,
  it: 180,
  ar: 60,
  tr: 180,
  ja: 60,
};

export const pdfLastPage = {
  Carrier: 'Carrier',
  Abound: 'Abound',
  Insights: 'Insights ',
  Assistant: 'Assistant ',
  Predictive: 'Predictive',
  SaaS: 'SaaS',
  managed: 'managed',
  services: 'services',
  artificial: 'artificial',
  intelligence: 'intelligence',
  platform: 'platform',
  aboundWebsite: 'abound.carrier.com',
  LinkedIn: 'LinkedIn',
  X: 'X',
  YouTubeChannel: 'YouTube channel',
  Facebook: 'Facebook',
};

export const COMBI_CODE_CHIP_SORT_ORDER = Object.freeze({
  insightStatus: 0,
  resolutionType: 1,
  quickTags: 2,
});

export const regexValidation =
  /[^A-Za-z0-9_\u4e00-\u9faF\u3040-\u30FF\u31F0-\u31FF\uFF00-\uFFEF\s]+/gu;

export const stylesForPdf = {
  dynamicContentUrgentHeader: {
    en: 20,
    es: 40,
    zh: 10,
    fr: 40,
    de: 40,
    nl: 40,
    it: 40,
    ar: 40,
    tr: 40,
    ja: 5,
  },
};
